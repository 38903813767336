<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:46:53
-->
<template>
	<div class="activityMyDetai">
		<div class="activityMyDetai-tab">
			<el-tabs v-model="currentTab" @tab-click="changeTab">
				<el-tab-pane label="待审核" name="HIDE"></el-tab-pane>
				<el-tab-pane label="通过审核" name="DID_NOT_PASS"></el-tab-pane>
			</el-tabs>
			<el-button @click="actionFn('add')" class="activityMyDetai-tab-btn">新增共建基地活动</el-button>
		</div>
		<div class="activityMyDetai-data">
			<el-table :data="tableData" border>
				<el-table-column width="200px" prop="id" label="活动ID">
				</el-table-column>
				<el-table-column prop="activityName" label="活动名称">
				</el-table-column>
				<el-table-column prop="activityType" label="活动分类">
					<template slot-scope="scope">
						<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
					</template>
				</el-table-column>
				<el-table-column width="250px" prop="type" label="活动时间">
					<template slot-scope="scope" v-if="scope.row.starTime&&scope.row.endTime">
						<div class="table-action-date">
							<span>{{`${dateFormat(scope.row.starTime)} 至 ${dateFormat(scope.row.endTime)}`}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="currentTab == 'DID_NOT_PASS'" prop="status" label="活动状态">
					<template v-if="scope.row.activityStatus*1 >= 0" slot-scope="scope">
						<div v-if="scope.row.activityStatus == 2" class="table-action">
							<span
								:class="{'table-action-status_orange': scope.row.workingStatus == 1,'table-action-status_red': scope.row.workingStatus == 2,'table-action-status_black': scope.row.workingStatus == 3}">{{scope.row.workingStatus == 1 ?"工时表待提交":scope.row.workingStatus == 2 ?"工时表超时未提交":"工时表已提交"}}</span>
						</div>
						<div v-else class="table-action">
							<span
								:class="{'table-action-status_black': scope.row.activityStatus != 0,'table-action-status_red': scope.row.activityStatus == 0}">{{scope.row.activityStatus == 0?"未通过":scope.row.activityStatus == 1?"审核中":scope.row.activityStatus == 2?"进行中":"已完成"}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="comBaseName" label="共建基地">
				</el-table-column>
				<el-table-column width="300px" prop="organizer" label="组织名称">
				</el-table-column>
				<el-table-column width="200px" prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span @click="actionFn('edit', scope)">编辑</span>
							<el-divider direction="vertical"></el-divider>
							<span @click="actionFn('detail', scope)">详情</span>
							<el-divider v-if="currentTab == 'HIDE'" direction="vertical"></el-divider>
							<span v-if="currentTab == 'HIDE'" @click="actionFn('delete', scope)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="common-pageNation">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { getBaseActivityList } from "@/api/base";
import { deleteActivity } from "@/api/activity";
export default {
  data() {
    return {
      currentTab: "HIDE",
      tableData: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 10,
      baseId: "",
      baseName: "",
      baseState: ""
    };
  },
  mounted() {
    this.baseId = this.$route.query.baseId;
    this.baseName = this.$route.query.baseName;
    this.baseState = this.$route.query.baseState;
    this.baseActivityList();
  },
  methods: {
    dateFormat(intDate) {
      if (intDate) {
        return intDate.replace(" 00:00:00", "");
      }
    },
    changeTab(e) {
      this.currentTab = e.name;
      this.pageIndex = 1;
      this.baseActivityList();
    },
    // 共建基地活动
    baseActivityList() {
      let params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        statusEnum: this.currentTab
      };
      this.tableData = [];
      getBaseActivityList(this.baseId, params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.totalCount = res.page.totalCount * 1;
        }
      });
    },
    currentChange(val) {
      this.pageIndex = val;
      this.tableData = [];
      this.baseActivityList();
    },
    actionFn(type, scope) {
      switch (type) {
        case "add":
          if (this.baseState != 0) {
            this.$router.push({
              path: `/activityMy/addAc?baseId=${this.baseId}&&baseName=${
                this.baseName
              }`
            });
          } else {
            this.$message.error("该共建基地已被禁用，无法新增共建基地活动~");
          }
          break;
        case "edit":
          // let routeEdit = this.$router.resolve({
          //   path:"/activityMy/editAc",
          //   query:{activityId:scope.row.id}
          // })
          // window.open(routeEdit.href,"_blank")
          this.$router.push({
            path: `/activityMy/editAc?activityId=${scope.row.id}`
          });
          break;
        case "detail":
          // let routeData = this.$router.resolve({
          //   path:"/activityMy/acDetail",
          //   query:{activityId:scope.row.id}
          // })
          // window.open(routeData.href,"_blank")
          this.$router.push({
            path: `/activityMy/acDetail?activityId=${scope.row.id}`
          });
          break;
        case "delete":
          this.$confirm("确定要删除此活动吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error"
          })
            .then(() => {
              deleteActivity(scope.row.id, { id: scope.row.id }).then(res => {
                if (res.code == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  });
                  this.pageIndex = 1;
                  this.baseActivityList();
                }
              });
            })
            .catch(() => {});
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  height: 36px;
  border-radius: 0px 2px 2px 0px;
  border: 1px solid #edf1f7;
  border-left: 0;
}

/deep/.el-button {
  height: 36px;
}

.table-action-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #303133;
  }
}

.table-action {
  .table-action-status_black {
    color: #303133;
  }

  .table-action-status_orange {
    color: #f5803e;
  }

  .table-action-status_red {
    color: #e63757;
  }
}

.activityMyDetai {
  display: flex;
  flex-direction: column;

  .activityMyDetai-tab {
    position: relative;

    .activityMyDetai-tab-btn {
      position: absolute;
      right: 20px;
      top: 9px;
    }
  }

  .activityMyDetai-data {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 16px;

    .table-action-status1 {
      color: #303133;
    }

    .table-action-status2 {
      color: #f5803e;
    }

    .table-action-status3 {
      color: #e63757;
    }
  }
}
</style>
